
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "baseApiUrl": "https://www.designerjourneys.com",
  "publicDomain": "https://www.designerjourneys.com",
  "publicDomainAgent": "https://agent.designerjourneys.com",
  "publicDomainAgentPreview": "https://www.mytrippreview.net",
  "publicDomainPreview": "https://www.mytrippreview.com",
  "adminDomain": "https://extranet.designerjourneys.com",
  "publicType": "traveller",
  "staticCDN": "https://s3-cdn.designerjourneys.com",
  "cdnSuffix": "/build/vprod-2.62.0.02",
  "logAPI": "/integration/v1/logs",
  "logWorkerSRC": "/logger.worker.js",
  "siteKeyGreCaptcha": "6Lc2KNAUAAAAAIi6vidFqWhVp-oq1T7TInRuwML3",
  "siteKeyGoogleMapJsAPI": "AIzaSyDYdOBTeKaTCG6cYBGt3dtjaa4mfoqhTj4",
  "siteKeyFacebook": "486019852015123",
  "heapID_TV": "133086983",
  "gmtID_TV": "GTM-WLB5PNW",
  "intrepidID": "2039",
  "paymentPinENV": "live",
  "paymentKeyUSD": "pk_Pdi6fIdbPmWzp-_tjQVf1A",
  "paymentKeyCAD": "pk_vkMBi1c1WHafgG4xfSNaog",
  "paymentKeyGBP": "pk_dvz32dQG00yazONQfMeVSg",
  "paymentKeyEUR": "pk_ArcDKhfnCIQiR78-XVrj6g",
  "paymentKeyNZD": "pk_YmfaYjhgk041KKIEfLbErg",
  "paymentKeyJPY": "pk_SkIu69L64a0sL5xLrw5kvg",
  "paymentKeyAUD": "pk_xQou5JE2pwBFyixEL0X95Q",
  "chatSdkJSUri": "https://s3-cdn.designerjourneys.com/chat-sdk/vprod-2.62.0.02/main.min.js",
  "chatServerUri": "https://chat-tool.designerjourneys.com",
  "chatConnAttempts": "12",
  "publicApiEndpoint": "https://www.designerjourneys.com/extranet/v2",
  "fileServiceUri": "/api/upload/v1/uploadMedia",
  "fileServiceUser": "root",
  "fileServicePasswd": "30Q9,hy;8?>axj{e72p",
  "useEqCaptureMultiSteps": "true",
  "nuxt": {}
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}



export default /*@__PURE__*/ defuFn(inlineConfig)
